<template>
  <div class="forget-password">

    <div class="forget">

      <p class="forget-title">重置密码</p>
      <div class="forget-content">
        <el-input v-model="userInfo.phone" size="default" placeholder="请输入手机号" suffix-icon="el-icon-mobile-phone">
        </el-input>

        <el-input v-model="userInfo.code" size="default" placeholder="请输入验证码">
          <template slot="append">
            <el-button type="text" size="small"
                       style="width: 24%;padding: 8px" @click="handleSendSms">{{ttlMsg}}
            </el-button>
          </template>
        </el-input>

        <el-input v-model="userInfo.password" size="default" placeholder="请输入密码" :show-password="true">
        </el-input>

        <el-input v-model="userInfo.rePassword" size="default" placeholder="请再次输入密码" :show-password="true">
        </el-input>

        <p class="reset">
          <el-button class="user-btn" type="primary" @click="resetPassword">重置密码</el-button>
        </p>
        <p class="forget-back">
          <span @click="handleBack"> <i class="iconfont iconfanhui"></i>  返回登录</span>
        </p>

      </div>
    </div>
  </div>
</template>

<script>
  const SMSTTL = 120
  import {
    checkPassword
  } from "@/utils/check";
  export default {
    name: '',
    props: {
      close: {
        Type: Boolean,
        default: true
      }
    },
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        ttl: 0,
        ttlMsg: '获取验证码',
        userInfo: {
          phone: '',
          code: '',
          password: '',
          rePassword: ''
        },
        ts: null
      }
    },
    methods: {
      handleSendSms() {
        if (!this.userInfo.phone) {
          this.$message.warning('请输入手机号码')
          return
        }
        if (this.ts ) {
          console.log(this.ts)
          return
        }

        this.$api('account.prove.sendSms', {phone: this.userInfo.phone}).then(() => {
          this.$message.success('发送成功')
          let vue = this
          vue.ttl = SMSTTL
          this.ts = setInterval(function () {
            if (vue.ttl <= 0) {
              clearInterval(vue.ts)
              vue.ttlMsg = `获取验证码`
            } else {
              vue.ttl -= 1
              vue.ttlMsg = `${vue.ttl} 秒`
            }
          }, 1000)
        })
      },
      handleBack() {
        this.$emit('update:close', true)
      },
      resetPassword () {
        let check = checkPassword(this.userInfo.password)
        if (!check.status) {
          this.$message.warning(check.msg)
          return
        }

        if (this.userInfo.password !== this.userInfo.rePassword) {
          this.$message.warning('两次密码不一致')
          return
        }

        this.$api('account.prove.smsGetCode', {},
          {phone: this.userInfo.phone, code: this.userInfo.code}).then(data => {
          this.$api('account.prove.modifyPassword',{},{code: data, password: this.userInfo.password}).then(() => {
            this.$message.success('重置成功，请重新登录');
            this.userInfo = {
              phone: '',
              code: '',
              password: '',
              rePassword: ''
            }

            if (this.ts) {
              clearInterval(this.ts)
            }

            this.$emit('update:close', true)
          })
        })

      }
    },

  }
</script>

<style lang="scss" scoped>
  .forget-password {
    @include board();
    height: 96%;
    flex: 1;
    max-width: 480px;
    margin: auto;
    border-radius: $box-radius;
    background-color: $color-white;

    .el-input {
      margin-bottom: $small-space;

    }

    .el-input, .el-input:focus {
      ::v-deep .el-input__inner {
        border-top: none !important;
        border-left: none;
        border-right: none;
        border-radius: 0;
        box-shadow: none;
      }

      ::v-deep .el-input-group__append {
        border: 1px;
      }
    }

    .reset {
      margin-top: $large-space;
      text-align: center;
    }


    .forget {
      margin: 0 64px;

      &-back {
        margin-top: $middle-space;
        cursor: pointer;
        text-align: center;
      }

      &-back:hover {
        color: $theme-color;
      }


      &-title {
        @include font-large();
        font-weight: 600;
        margin-top: 48px;
        margin-bottom: 32px;
        text-align: center;
        color: $theme-color;
      }
    }
  }


</style>
